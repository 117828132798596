import { getObjectFromLocalStorage } from "../../api/localStorage";
import { CRMS, FSC_STEP_TYPE } from "../../utils/constants";
import {
  AI_MODEL,
  AI_MODEL_FIELDS_IN_COMPANY,
  BULLHORN_CAMPAIGN_TYPES,
  FIRST_WIZARD_TAB_NUM,
  NATIONBUILDER_CAMPAIGN_TYPES,
  NONPROFIT_CAMPAIGN_TYPES,
} from "./constants";

function isValidUrl(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
}

const getDateForJobTitle = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

const buildMarketingCampaignName = () => {
  return `Reach Campaign ${getDateForJobTitle()}`;
};

const isBullhornMarketingCampaignType = (type) => {
  return (
    type === BULLHORN_CAMPAIGN_TYPES.marketToCandidates ||
    type === BULLHORN_CAMPAIGN_TYPES.marketToClientLeads ||
    type === BULLHORN_CAMPAIGN_TYPES.marketToClientContacts
  );
};

const getWizardTabs = (crm, type) => {
  const isMarketingCampaign =
    crm !== CRMS.BULLHORN || isBullhornMarketingCampaignType(type);

  const tabsList = [
    "CAMPAIGN_OVERVIEW",
    ...(crm === CRMS.NATIONBUILDER ? [] : ["CAMPAIGN_TYPE"]),
    isMarketingCampaign ? "CAMPAIGN_DETAILS" : "JOB_DESCRIPTION",
    "SALES_STRATEGY",
    "SELECT_RECIPIENTS",
    "CONFIGURE_OPTIONS",
    "REVIEW_MESSAGES",
  ];

  return tabsList.reduce(
    (a, c, i) => ({ ...a, [c]: i + FIRST_WIZARD_TAB_NUM }),
    {}
  );
};

const hasSmsStepInSequence = (sequenceTemplate, sequenceTemplateOptions) => {
  return sequenceTemplate
    ? sequenceTemplateOptions
        ?.find((o) => o.value === sequenceTemplate)
        ?.steps?.find((s) => s.type === FSC_STEP_TYPE.SMS)
    : null;
};

const hasEmailStepInSequence = (sequenceTemplate, sequenceTemplateOptions) => {
  return sequenceTemplate
    ? sequenceTemplateOptions
        ?.find((o) => o.value === sequenceTemplate)
        ?.steps?.find(
          (s) =>
            s.type === FSC_STEP_TYPE.EMAIL ||
            s.type === FSC_STEP_TYPE.MARKETING_EMAIL
        )
    : null;
};

const isNotHaveNonDefaultAiModel = (companyInfo, value) => {
  return (
    value !== AI_MODEL.DEFAULT &&
    !companyInfo?.company?.aiModel?.[AI_MODEL_FIELDS_IN_COMPANY[value]] &&
    !companyInfo?.company?.[AI_MODEL_FIELDS_IN_COMPANY[value]]
  );
};

/**
 * Returns the campaign types for the specified CRM
 * @param {string} crmType
 * @returns {string[]}
 */
const getCrmCampaignTypes = (crmType) => {
  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const isNonprofit = !!companyInfo?.company?.isNonprofit;

  if (isNonprofit) {
    return Object.values(NONPROFIT_CAMPAIGN_TYPES);
  }

  if (crmType === CRMS.BULLHORN) {
    return Object.values(BULLHORN_CAMPAIGN_TYPES);
  }

  if (crmType === CRMS.NATIONBUILDER) {
    return Object.values(NATIONBUILDER_CAMPAIGN_TYPES);
  }

  return [
    "Promote a product",
    "Promote a service",
    "Stay in touch with my customers",
  ];
};

export {
  isValidUrl,
  buildMarketingCampaignName,
  isBullhornMarketingCampaignType,
  getWizardTabs,
  hasSmsStepInSequence,
  hasEmailStepInSequence,
  isNotHaveNonDefaultAiModel,
  getCrmCampaignTypes,
};
